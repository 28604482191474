import React from "react";
import TopTextSection from "./topTextSection";
import BottomImageContainer from "./bottomImageContainer";

const HiddenSignGen = ({ tableRef, details }) => {
  return (
    <div
      //   style={{ position: "sticky", zIndex: "-100", color: "red", top: "0" }}
      className="hidden"
    >
      <div>
        <div ref={tableRef}>
          <div className="giveMinWidth">
            <table
              className="signGenContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                color: "black",
                verticalAlign: "text-top",
              }}
            >
              <tbody>
                {/* className="tableRowContianer" */}
                <tr style={{ maxWidth: "100%" }}>
                  {/* <td
                    // className="tableDataContianer"
                    style={{
                      padding: "10px",
                      paddingTop: "5px",
                      verticalAlign: "top",
                    }}
                  >
                  </td> */}
                  <td>
                    <img
                      // className="logoContianer"
                      // style={{ width: "64px" }}
                      width={58}
                      src="https://bimventures.com/wp-content/uploads/2023/06/Untitled-1-1.png"
                      alt="Logo"
                    />
                    <TopTextSection details={details}></TopTextSection>
                    <BottomImageContainer></BottomImageContainer>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                color: "#808080",
                fontSize: "10px",
              }}
            >
              Disclaimer: This message and its attachment, if any, are
              confidential and may contain legally privileged information. If
              you are not the intended recipient, please contact the sender
              immediately and delete this message and its attachment, if any,
              from your system. You should not copy this message or disclose its
              contents to any other person or use it for any purpose. Statements
              and opinions expressed in this e-mail are those of the sender, and
              do not necessarily reflect those of Business Innovation Mine
              L.L.C. (BIM). BIM accepts no liability for damage caused by any
              virus transmitted by this email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiddenSignGen;

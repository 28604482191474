import React from "react";

const BottomImageContainer = () => {
  return (
    <>
      <div>
        {/* <img
          // className="imageContainer"
          height={66}
          style={{
            display: "flex",
            margin: "5px 0px 2px 0px",
            maxWidth: "100%",
          }}
          src="https://lh3.googleusercontent.com/d/1eGcRkMWavSTlTYQ3aZUZP-x440XQ92qn"
          alt="LogoFull"
        /> */}
      </div>
    </>
  );
};

export default BottomImageContainer;

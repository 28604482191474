import React from "react";

const TopTextSection = ({ details }) => {
  return (
    <>
      <p
        style={{
          color: "#001b5e",
          margin: "6px 6px 0px 0px",
          // fontSize: "12px",
          fontWeight: "700",
        }}
      >
        {details.fullNameE}{" "}
        {details.fullNameE && details.fullNameA && <span>|</span>}{" "}
        {details.fullNameA}
      </p>

      <p
        // className="phone"
        style={{
          color: "#00c38b",
          // fontSize: "12px",
          margin: "0",
          marginTop: "2px",
          fontWeight: "700",
        }}
      >
        {details.positionE}{" "}
        {details.positionE && details.positionA && <span>|</span>}{" "}
        {details.positionA}
      </p>

      <p
        style={{
          color: "#b7b7b7",
          // fontSize: "12px",
          margin: "0",
          marginTop: "2px",
        }}
      >
        +{details.location.phone}{" "}
        {details.location.phone.length === 3
          ? ` ${details.phoneNumber.slice(0, 2)} 
                ${details.phoneNumber.slice(2, 5)} 
                ${details.phoneNumber.slice(5, 9)}`
          : ` ${details.phoneNumber.slice(0, 3)} 
                ${details.phoneNumber.slice(3, 6)} 
                ${details.phoneNumber.slice(6, 10)}`}
      </p>
      <p
        style={{
          // fontSize: "10px",
          margin: "0",
        }}
      >
        <a
          style={{
            textDecorationLine: "none",
          }}
          href="https://lynk.sa/"
        >
          Lynk.sa
        </a>
      </p>
    </>
  );
};

export default TopTextSection;

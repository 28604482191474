import React from "react";
import TopTextSection from "./topTextSection";
import BottomImageContainer from "./bottomImageContainer";
import ActionButtons from "./actionButtons";
import "./signGen.css";
import HiddenSignGen from "./HiddenSignGen";

const SignGen = ({ details, fastFill }) => {
  const tableRef = React.useRef(null);
  const leaveRef = React.useRef(null);

  const copyTextWithStyles = async (element) => {
    let userAgent = navigator.userAgent;

    //if firefox copy the text with this method else use the other method
    if (userAgent.match(/firefox|fxios/i)) {
      window.getSelection().selectAllChildren(element);
      document.execCommand("Copy");
      // window.getSelection().selectAllChildren(leaveRef.current);
    } else {
      const html = element.innerHTML;
      const htmlBlob = new Blob([html], { type: "text/html" });
      const text = element.textContent ?? "";
      const textBlob = new Blob([text], { type: "text/plain" });
      // eslint-disable-next-line no-undef
      const clipboardItem = new ClipboardItem({
        [htmlBlob.type]: htmlBlob,
        [textBlob.type]: textBlob,
      });
      return await navigator.clipboard.write([clipboardItem]);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="giveMinWidth">
            <div style={{ maxWidth: "100%" }}>
              <img
                // className="logoContianer"
                // style={{ width: "64px" }}
                width={64}
                src="https://bimventures.com/wp-content/uploads/2023/06/Untitled-1-1.png"
                alt="Logo"
              />
            </div>
            <table
              className="signGenContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                color: "black",
                verticalAlign: "text-top",
              }}
            >
              <tbody>
                {/* className="tableRowContianer" */}

                <tr style={{ maxWidth: "100%" }}>
                  {/* <td
                    // className="tableDataContianer"
                    style={{
                      padding: "10px",
                      paddingTop: "5px",
                      verticalAlign: "top",
                    }}
                  >
                  </td> */}
                  <td>
                    <TopTextSection details={details}></TopTextSection>
                    {/* <BottomImageContainer></BottomImageContainer> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <HiddenSignGen details={details} tableRef={tableRef}></HiddenSignGen>

      <ActionButtons
        copySignature={() => copyTextWithStyles(tableRef.current)}
        fastFill={fastFill}
      ></ActionButtons>
      <div ref={leaveRef}></div>
    </div>
  );
};

export default SignGen;

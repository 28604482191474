import React from "react";
import { InputAdornment, Button } from "@mui/material";
import TextInput from "./textInput";
import DepartmentInput from "./departmentInput";
import LocationInput from "./locationInput";
import "./signForm.css";

const SignForm = ({ details, detailsHandler, handleOpen }) => {
  const phoneLimit = details.location.phone.length === 3 ? 10 : 11;
  const handleChanges = (e, value) => {
    if (value === "phoneNumber") {
      //1 all none digits > turns arabic digits to english > stops the first digit from being a 0 and limits the amount of digits depending on country code (might need changing)
      //you can change the amount of digits allowed by playing with the phoneLimit variable
      let phoneValue = e.target.value.replace(/[^0-9\u0600-\u06FF]/g, ""); //\u0600-\u06FF === unicode for arabic numbers
      phoneValue = convertArabicNumbersToEnglish(phoneValue); //function is below, it converts arabic digits to english digits
      detailsHandler({
        ...details,
        [value]:
          phoneValue[0] === "0"
            ? phoneValue.slice(1, phoneLimit)
            : phoneValue.slice(0, phoneLimit - 1),
      });
    } else if (value === "positionE" || value === "fullNameE") {
      detailsHandler({
        ...details,
        [value]: e.target.value.replace(/[^a-zA-Z0-9\s$-/:-?{-~!"^_`[\]]/g, ""),
      });
    } else if (value === "positionA" || value === "fullNameA") {
      detailsHandler({
        ...details,
        [value]: e.target.value.replace(
          /[^\u0621-\u064A\u0660-\u0669\u066E-\u06D5\u06EE\u06EF\u06FA-\u06FC\u06FF\s]/g, //unicode for arabic characters
          ""
        ),
      });
    } else {
      detailsHandler({ ...details, [value]: e.target.value });
    }
  };

  const handleSelect = (e, value) => {
    if (e === null) {
      return;
    }
    detailsHandler({ ...details, [value]: e });
  };

  const convertArabicNumbersToEnglish = (text) => {
    const arabic = "٠١٢٣٤٥٦٧٨٩";
    const english = "0123456789";
    return text.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => {
      return english[arabic.indexOf(d)];
    });
  };

  return (
    <div className="signFormContainer">
      <div>
        <img
          className="formLogoContainer"
          src="https://bimventures.com/wp-content/uploads/2023/06/Untitled-1-1.png"
          alt="Logo"
        />
      </div>
      <h1 className="titleHolder">BIM Signature Generator</h1>

      <div className="formContainer">
        <TextInput
          handleChanges={handleChanges}
          value={details.fullNameE}
          valueName={"fullNameE"}
          label={"Full Name (English) *"}
        ></TextInput>
        <TextInput
          handleChanges={handleChanges}
          value={details.fullNameA}
          valueName={"fullNameA"}
          label={"Full Name (Arabic) *"}
        ></TextInput>

        {/* <DepartmentInput
          value={details.department}
          handleChanges={handleChanges}
        ></DepartmentInput> */}

        <TextInput
          handleChanges={handleChanges}
          value={details.positionE}
          valueName={"positionE"}
          label={"Position (English) *"}
        ></TextInput>
        <TextInput
          handleChanges={handleChanges}
          value={details.positionA}
          valueName={"positionA"}
          label={"Position (Arabic) *"}
        ></TextInput>

        <LocationInput
          value={details.location}
          handleSelect={handleSelect}
        ></LocationInput>

        <TextInput
          handleChanges={handleChanges}
          value={details.phoneNumber}
          valueName={"phoneNumber"}
          label={"Phone Number *"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                +{details.location.phone}
              </InputAdornment>
            ),
          }}
        ></TextInput>

        <div className="inputContianer">
          <Button
            color="primary"
            variant="outlined"
            type="click"
            onClick={handleOpen}
          >
            how to apply signature
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignForm;
